import * as React from 'react';

import Layout from '../components/layout';
import Navbar from '../components/navbar';
import Seo from '../components/seo';

function WebsiteDesignStThomas() {
  return (
    <Layout>
      <Navbar />
      <Seo title="Web Design St. Thomas" />

      <div className="w-full py-20 text-gray-500">
        <div className="w-4/6 mx-auto">
          <h1 className="text-3xl font-extrabold tracking-tight text-gray-500 ">
            Web Design & Development in St. Thomas
          </h1>
          <p className="prose-lg pt-2">
            Bailey Tech is a St. Thomas, ON based web design and development
            service. As a local small business owner, we know how important it
            is for your website make a strong first impression. We design and
            develop websites from scratch based on industry best practices.
          </p>
          <h2 className="prose-2xl pt-5 font-semibold">
            Fast Loading Websites
          </h2>
          <p className="prose-lg pt-2">
            Even before a customer even sees your website. They start judging it
            based on how fast it takes them to see your page.
          </p>
          <blockquote className="prose-2xl pt-5 font-semibold text-blue-500">
            "53% of mobile site visits are abandoned if pages take longer than 3
            seconds to load. "
          </blockquote>
          <p className="prose-lg pt-2">
            We custom design websites using CSS3, HTML5 to meet the precise
            needs of your business.
          </p>
          <h2 className="prose-2xl pt-5 font-semibold">
            Professional on Any Screen Size
          </h2>
          <p className="prose-lg pt-2">
            Customers expect to be able to view your website on their mobile
            devices. It's vital that your website adapts and looks professional
            no matter the screen size.
          </p>
          <p className="prose-lg pt-2">
            We design websites using a mobile design first approach. This
            ensures that customers can use the website on any device.
          </p>
          <h2 className="prose-2xl pt-5 font-semibold">
            Clear Goals for Your Website
          </h2>
          <p className="prose-lg pt-2">
            In order for a website to be effective each page should have a clear
            goal. Whether that is to learn more about your business, complete a
            form, or to call.
          </p>
          <p className="prose-lg pt-2">
            We work with owners to discover the most important action for their
            business. Then recommend and layout every page to guide customers
            towards the desired action.
          </p>
          <p className="justify-center text-center font-semibold text-2xl py-10">
            Got A Project Or Need Advice? Let’s Talk About It. 519-870-5842
          </p>
        </div>
      </div>
    </Layout>
  );
}

export default WebsiteDesignStThomas;
