import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function Navbar() {
  return (
    <div className="bg-gray-600 flex py-1 ">
      <div className="w-3/4 flex  justify-between items-center mx-auto">
        <div className="flex w-full sm:w-2/5 lg:w-3/4 justify-center lg:justify-start">
          <StaticImage
            src="../images/bailey-tech-logo.png"
            width={430}
            quality={95}
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="Custom Web Design"
          />
        </div>
        <div className="text-gray-100 hidden sm:block w-1/4 sm:w-2/5 md:flex md:justify-center">
          <p className="font-semibold md:text-lg lg:text-xl">
            Call Us:
            <a href="tel:519-870-5842" className="pl-4">
              519-870-5842
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
